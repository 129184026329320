<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="H5活动审核"
        >
        </a-page-header>
      </div>
      <div ref="tabs">
        <a-tabs tabPosition="top" @tabClick="callback">
          <a-tab-pane
            v-for="i in checkTitle"
            :key="i.status"
            :tab="i.name"
          ></a-tab-pane>
        </a-tabs>
      </div>
      <a-table
        :columns="columns"
        :data-source="h5List"
        :pagination="false"
        :row-key="(record) => record.id"
        :scroll="{ x: 1000, y: table_h }"
        size="middle"
        ref="tableList"
        class="components-table-demo-nested"
      >
        <span slot="btns" slot-scope="btns, num">
          <a-button
            class="editable-add-btn"
            v-for="btn in btns"
            :key="btn"
            @click="handel(btn, num)"
            size="small"
            style="margin: 5px"
          >
            {{ btn }}
          </a-button>
        </span>
        <!-- <template slot="footer"> Footer </template> -->
      </a-table>
      <div ref="pagination">
        <a-pagination
          v-if="pageshow"
          :default-current="currentPage"
          :defaultPageSize="defaultPageSize"
          :page-size-options="pageSizeOptions"
          show-size-changer
          show-quick-jumper
          :total="total_num"
          :show-total="(total) => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal
      v-model="visible"
      :title="modelTitle"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      ok-text="确认"
      cancel-text="取消"
    >
      <a-form :form="addform" @submit="handleSubmit">
        <a-form-item label="拒绝原因：">
          <a-input
            v-decorator="[
              'reasons_refusal',
              {
                rules: [{ required: true, message: '请输入拒绝原因!' }],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model="agreevisible"
      :title="modelTitle"
      :confirm-loading="confirmLoading"
      @ok="handleAgreeOk"
      ok-text="确认"
      cancel-text="取消"
    >
      <a-form :form="agreeform" @submit="handleSubmit">
        <a-form-item label="PID：">
          <a-input
            v-decorator="[
              'material.value',
              {
                rules: [{ required: true, message: '请输入PID!' }],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      export: 0,
      columns: [
        {
          title: "用户昵称",
          key: "users.nickname",
          dataIndex: "users.nickname",
          align: "center",
        },
        {
          title: "用户手机号",
          key: "users.mobile",
          dataIndex: "users.mobile",
          align: "center",
        },
        {
          title: "活动类型",
          key: "a_id",
          dataIndex: "a_id",
          align: "center",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
          width: 200,
          fixed: "right",
        },
      ],
      checkTitle: [
        { name: "待审核", status: 1 },
        { name: "审核成功", status: 2 },
        { name: "审核失败", status: 3 },
      ],
      activeTab: 1,
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      h5List: [],
      sort_model: "",
      sort_by: "",
      addform: this.$form.createForm(this, { name: "city_add" }),
      agreeform: this.$form.createForm(this, { name: " agree_add" }),
      visible: false,
      agreevisible: false,
      modelTitle: "",
      confirmLoading: false,
      id:''
    };
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({ status: 1 });
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.tabs.offsetHeight -
        this.$refs.pagination.offsetHeight -
        45;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    callback(val) {
      this.activeTab = val;
      this.currentPage = 1;

      // this.sort_by = "";
      // this.sort_model = "";
      if (val == 1) {
        this.columns = [
          {
            title: "用户昵称",
            key: "users.nickname",
            dataIndex: "users.nickname",
            align: "center",
          },
          {
            title: "用户手机号",
            key: "users.mobile",
            dataIndex: "users.mobile",
            align: "center",
          },
          {
            title: "活动类型",
            key: "a_id",
            dataIndex: "a_id",
            align: "center",
          },
          {
            title: "创建时间",
            key: "created_at",
            dataIndex: "created_at",
            align: "center",
          },
          {
            title: "操作",
            key: "btns",
            dataIndex: "btns",
            scopedSlots: { customRender: "btns" },
            align: "center",
            width: 200,
            fixed: "right",
          },
        ];
      } else if (val == 2) {
        this.columns = [
          {
            title: "用户昵称",
            key: "users.nickname",
            dataIndex: "users.nickname",
            align: "center",
          },
          {
            title: "用户手机号",
            key: "users.mobile",
            dataIndex: "users.mobile",
            align: "center",
          },
          {
            title: "活动类型",
            key: "a_id",
            dataIndex: "a_id",
            align: "center",
          },
          {
            title: "PID",
            key: "material.value",
            dataIndex: "material.value",
            align: "center",
          },
          {
            title: "创建时间",
            key: "created_at",
            dataIndex: "created_at",
            align: "center",
          },
        ];
      } else {
        this.columns = [
          {
            title: "用户昵称",
            key: "users.nickname",
            dataIndex: "users.nickname",
            align: "center",
          },
          {
            title: "用户手机号",
            key: "users.mobile",
            dataIndex: "users.mobile",
            align: "center",
          },
          {
            title: "活动类型",
            key: "a_id",
            dataIndex: "a_id",
            align: "center",
          },
          {
            title: "拒绝原因",
            key: "reasons_refusal",
            dataIndex: "reasons_refusal",
            align: "center",
          },
          {
            title: "创建时间",
            key: "created_at",
            dataIndex: "created_at",
            align: "center",
          },
        ];
      }
      this.getSearchList({ status: val });
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      // that.form.validateFields((error, values) => {
      that.getSearchList({ status: this.activeTab });
      // });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      if (that.sort_by) {
        data.sort_by = that.sort_by;
      }
      if (that.sort_model) {
        data.sort_model = that.sort_model;
      }
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/activityJoinH5Verify/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var h5List = res.data.data;
            for (var i = 0; i < h5List.length; i++) {
              if (data.status == 1) {
                h5List[i].btns = ["通过", "不通过"];
              } else {
                h5List[i].btns = [];
              }
            }
            that.changeTableH();
            that.h5List = h5List;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },

    // 操作（通过、不通过）
    handel(btn, num) {
      var that = this;
      that.id = num.id;
      if (btn == "不通过") {
        that.visible = true;
        that.modelTitle = "审核不通过";
        that.confirmLoading = false;
        that.$nextTick(() => {
          that.addform.setFieldsValue({
            denial_reason: "",
          });
        });
      }
      if (btn == "通过") {
        that.agreevisible = true;
        that.modelTitle = "审核通过";
        that.confirmLoading = false;
        that.$nextTick(() => {
          that.agreeform.setFieldsValue({
            material:{
              value:''
            },
          });
        });
      }
    },
    //拒绝原因
    handleOk() {
      var that = this;
      that.confirmLoading = true;
      that.addform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          data.id = that.id;
          that.axios
            .post("/admin/activityJoinH5Verify/refuse",data)
            .then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.$message.success("已审核");
                that.getSearchList({ status: that.activeTab });
              }
              that.visible = false;
              that.confirmLoading = false;
            });
        } else {
          that.confirmLoading = false;
        }
      });
      // this.visible = false;
    },
    //通过
    handleAgreeOk() {
      var that = this;
      that.confirmLoading = true;
      that.agreeform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          data.id = that.id;
          data.material.name="PID"
          that.axios
            .post("/admin/activityJoinH5Verify/agree",data)
            .then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.$message.success("已审核");
                that.getSearchList({ status: that.activeTab });
              }
              that.agreevisible = false;
              that.confirmLoading = false;
            });
        } else {
          that.confirmLoading = false;
        }
      });
      // this.visible = false;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      this.export = 0;
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
/* .ant-tabs {
  flex-grow: 1;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.ant-tabs::-webkit-scrollbar {
  width: 0 !important;
} */
table img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
table .screenshot {
  width: 60px;
  height: auto;
  border-radius: 0;
}
.ant-table-wrapper {
  flex: 1;
}
.ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-body div {
  text-align: center;
  margin: 0 20px;
}
.ant-modal-body img {
  width: 150px;
}
.ant-modal-body div {
  width: 100%;
  text-align: left;
  margin: 0;
}
/* .section.ant-layout .container .ant-table-wrapper {
  overflow: scroll;
} */
.section.ant-layout .container .ant-table-wrapper::-webkit-scrollbar {
  width: 0;
}
/deep/.vue-directive-image-previewer-img {
  width: auto;
}
</style>